<template>
  <div v-if="section" class="row">
    <!-- <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Padding" label="Padding" />
    </div> -->
    <div class="col-md-6">
      <ColorInput v-model="section.ItemBackground" label="Item background"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.ItemColor" label="Item color"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.IconBackground" label="Icon background"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.IconColor" label="Icon color"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.UnlockIconBackground" label="Unlock icon background"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.UnlockIconColor" label="Unlock icon color"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.TabBackground" label="Tab background"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.UnlockTabBackground" label="Unlock tab color"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Title</label>
        <input
          v-model="section.Title"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Success text</label>
        <textarea
          v-model="section.SuccessText"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Unlock text</label>
        <textarea
          v-model="section.UnlockText"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <ColorInput v-model="section.HeadingColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Descripiton</label>
        <textarea
          v-model="section.Description"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.DescriptionColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionFontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionPadding" label="Padding" />
    </div> -->
  </div>
</template>

<script>
import ColorInput from '../Sections/ColorInput'
// import NumberInput from '../Sections/NumberInput'
// import AlignInput from '../Sections/AlignInput'

export default {
  components: {
    // NumberInput,
    // AlignInput,
    ColorInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
