<template>
  <div v-if="section" class="row">
    <!-- <div class="col-md-6">
      <ColorInput v-model="section.ItemBackground" label="Item background" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.ItemColor" label="Item color" />
    </div> -->
    <div class="col-md-12">
      <div class="form-group">
        <label>Title</label>
        <input v-model="section.Title" class="form-control" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Content</label>
        <textarea
          v-model="section.Content"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div
      v-for="(item, index) in landing.points"
      :key="`action-${index}`"
    >
      <div
        v-if="item.active && index !== 'OptIn' && index !== 'ReferralOptIn'"
        >
        <div
          class="row d-flex mt-2"
        >
          <IconInput v-model="landing.points[index].image"></IconInput>
          <!-- <UploadImageInput v-model="landing.points[index].image" /> -->
          <div>
            <input
              v-model="landing.points[index].label"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="landing.points[index].points" />
          </div>
        </div>
        <div class="row d-flex mt-2">
          <div class="col-md-6">
            <ColorInput v-model="landing.points[index].itemBackground" label="Item background" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="landing.points[index].itemColor" label="Item color" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in landing.custom_urls"
      :key="`action-${index}`"
    >
      <div
        v-if="item.active"
        >
        <div
          class="row d-flex mt-2"
        >
          <IconInput v-model="landing.custom_urls[index].image"></IconInput>
          <div>
            <input
              v-model="landing.custom_urls[index].label"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="landing.custom_urls[index].points" />
          </div>
        </div>
        <div class="row d-flex mt-2">
          <div class="col-md-6">
            <ColorInput v-model="landing.custom_urls[index].itemBackground" label="Item background" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="landing.custom_urls[index].itemColor" label="Item color" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <ColorInput v-model="section.HeadingColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Descripiton</label>
        <textarea
          v-model="section.Description"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.DescriptionColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionFontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionPadding" label="Padding" />
    </div> --> 
  </div>
</template>

<script>
import ColorInput from '../Sections/ColorInput'
import IconInput from '../Sections/IconInput'
// import NumberInput from '../Sections/NumberInput'
// import AlignInput from '../Sections/AlignInput'

export default {
  components: {
    // NumberInput,
    // AlignInput,
    ColorInput,
    IconInput,
  },
  props: {
    section: {
      type: [Object],
      default: null,
    },
    landing: {
      type: [Object],
      default: null,
    },
  },

  mounted() {
        let points = []
        if (this.landing.points)
        {
          Object.keys(this.landing.points).filter(c => !['OptIn', 'ReferralOptIn'].includes(c) && this.landing.points[c].active)
        }

        points.map(p => {
          if(!("label" in this.landing.points[p]) === "undefined" || !this.landing.points[p].label) {
            let action = p.match(/Click/) ? 'Click ' + p.replace(/(Click|Share)/, '') : 'Share on ' + p.replace(/(Click|Share)/, '')
          this.landing.points[p].label = action
          }
          
          let bgColor = '#DF0303FF'
          bgColor = p.match(/Facebook/) ? '#3b5998' : bgColor
          bgColor = p.match(/Twitter/) ? '#1da1f2' : bgColor
          bgColor = p.match(/Linkedin/) ? '#007bb5' : bgColor
          bgColor = p.match(/Pinterest/) ? '#cb2027' : bgColor

          this.landing.points[p].itemBackground = this.landing.points[p].itemBackground ? this.landing.points[p].itemBackground : bgColor
          this.landing.points[p].itemColor = this.landing.points[p].itemColor ? this.landing.points[p].itemColor : '#FFFFFF'


        let customUrls = Object.keys(this.landing.custom_urls).filter(c => this.landing.custom_urls[c].active)
        customUrls.map(c => {
          if(!("label" in this.landing.custom_urls[c]) === "undefined" || !this.landing.custom_urls[c].label) {
            let action = c
            this.landing.custom_urls[c].label = action
          }
          
          bgColor = '#3b5998'

          this.landing.custom_urls[c].itemBackground = this.landing.custom_urls[c].itemBackground ? this.landing.custom_urls[c].itemBackground : bgColor
          this.landing.custom_urls[c].itemColor = this.landing.custom_urls[c].itemColor ? this.landing.custom_urls[c].itemColor : '#FFFFFF'

        })
      })
  }

}
</script>
