<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.Color" label="Color"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Header Label 1</label>
        <textarea
          v-model="section.HeaderLabel1"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Header Label 2</label>
        <textarea
          v-model="section.HeaderLabel2"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Header Label 3</label>
        <textarea
          v-model="section.HeaderLabel3"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Header Label4</label>
        <textarea
          v-model="section.HeaderLabel4"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Title</label>
        <textarea
          v-model="section.Title"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.ContentBackground" label="Content Background"/>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.ContentColor" label="Content Color"/>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Content</label>
        <textarea
          v-model="section.Content"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Description</label>
        <textarea
          v-model="section.Description"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
   
  </div>
</template>

<script>
import ColorInput from '../Sections/ColorInput'
import AlignInput from '../Sections/AlignInput'

export default {
  components: {
    AlignInput,
    ColorInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
