<template>
<div class="landing-builder-container">
  <div v-if="selectedMode === 'design'" class="design-box">
    <div class="card">
      <div class="card-body">
        <div role="tablist" class="custom-accordion ml-4">
          <b-card
            v-for="section in sections"
            :key="section.key"
            class="mb-1"
            no-body
            >
            <b-card-header header-tag="header" role="tab">
              <a href="javascript: void(0);" class="text-black">
                <h5 class="m-0 font-size-14" @click="onTabClick(section.key)">
                  <i
                  :class="{
                      'uil-minus-circle text-warning':
                      selectedTab !== section.key,
                      'uil-plus-circle text-success':
                      selectedTab === section.key,
                  }"
                  class="uil h3 icon"
                  ></i>
                  {{ section.label }}
                </h5>
              </a>
            </b-card-header>
            <b-collapse
              accordion="accordion"
              role="tabpanel"
              :visible="selectedTab === section.key"
            >
              <b-card-body class="px-0">
              <component
                :is="section.component"
                :section="landing.loops[section.key]"
                :landing="landing"
              />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
  <div class="preview-box">
    <div class="preview-container">
      <b-tabs>
        <b-tab
          title="Design"
          active
          @click="selectMode('design')"
        >
          <Thanks
            ref="widget"
            :landing="landing"
            :page-mode="selectedMode"
            :selected-tab="selectedTab"
            @select-block="onTabClick"
          />
        </b-tab>
        <b-tab title="Preview" @click="selectMode('preview')">
          <div>
            <Thanks
              :landing="landing" 
              :page-mode="selectedMode"
              :selected-tab="selectedTab"
              @select-block="onTabClick"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</div>
</template>

<script>
import StylingSection from './Loops/StylingSection'
import MainSection from './Loops/MainSection'
import PointsSection from './Loops/PointsSection'
import RewardsSection from './Loops/RewardsSection'
import Thanks from './Thanks'

export default {
  components: {
    StylingSection, MainSection, PointsSection,
    Thanks,
  },

  props: {
    landing: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      sections: [
        {
          key: 'styling',
          label: 'Styling',
          component: StylingSection,
        },
        {
          key: 'main',
          label: 'Main',
          component: MainSection,
        },
        {
          key: 'points',
          label: 'Get Points',
          component: PointsSection,
        },
        {
          key: 'rewards',
          label: 'Rewards',
          component: RewardsSection,
        }
      ],
      selectedTab: null,
      selectedMode: 'design',
    }
  },

  methods: {
    selectMode(mode) {
      this.selectedMode = mode
    },

    onTabClick(section) {
      this.selectedTab = section
    },
  }
}
</script>

<style lang="scss">
.landing-builder-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  .nav-tabs {
    justify-content: center;
    a {
      opacity: 0.6;
      &.active {
        background: transparent;
        color: #495057;
        opacity: 1;
      }
    }
  }
}
</style>