<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <div class="d-flex mb-3">
        <label class="mr-2">Full Screen</label>
        <b-form-checkbox
          v-model="section.Fullscreen"
          switch
        ></b-form-checkbox>
      </div>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Roudness" label="Roudness" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.MaxWidth" label="Max. width" />
    </div>
  </div>
</template>

<script>
import NumberInput from '../Sections/NumberInput'

export default {
  components: {
    NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>
